<template>
  <div :class="containerClassRenderer">
    <s-text v-if="label" as="span" role="text4" class="bold-medium min-w-56">{{ label }}</s-text>
    <div class="w-[14.6rem]">
      <datepicker
        :modelValue="date"
        :format="getFormatByLocale(false)"
        :placeholder="getFormatByLocale(false).toUpperCase()"
        minimumView="day"
        maximumView="year"
        size="sm"
        :closeOnClick="true"
        :useFullMonthName="false"
        :isInline="false"
        :useMondayFirst="false"
        :isDisabled="false"
        :isRequired="false"
        :isTypeable="false"
        :useUtc="false"
        :disabledDates="disabledDates"
        :disabled="isDisabled"
        :language="locale === 'ko' ? LanguageCode.Ko : LanguageCode.En"
        @update:modelValue="changeDate"
      />
    </div>
    <div class="flex gap-4 items-center">
      <div class="w-[10rem] relative" :class="classHourAndMinutes">
        <dropdown
          :key="`datetime-picker-dropdown-hour-${hour}`"
          :modelValue="hour"
          :options="hours"
          :disabled="isDisabled"
          :dropdownProps="{
            size: 'sm',
            variant: 'line',
            distance: 0,
            offset: [0, 0],
            placement: 'bottom-start',
            class: 'w-full'
          }"
          closeOnClick
          @update:modelValue="changeHour"
        />
      </div>
      <span class="text-2xl leading-xl font-bold text-placeholder">:</span>
      <div class="w-[10rem] relative" :class="classHourAndMinutes">
        <dropdown
          :key="`datetime-picker-dropdown-minute-${minute}`"
          :modelValue="minute"
          :options="minutes"
          :disabled="isDisabled"
          :dropdownProps="{
            size: 'sm',
            variant: 'line',
            distance: 0,
            offset: [0, 0],
            placement: 'bottom-start',
            class: 'w-full'
          }"
          closeOnClick
          @update:modelValue="changeMinute"
        />
      </div>
    </div>
    <span
      v-if="showUtc"
      class="text-sm leading-md font-medium text-placeholder self-center text-nowrap ml-[-.8rem]"
    >
      ({{ utcTimezone }})
    </span>
  </div>
  <st-error-message v-if="!noShowError" :name="fieldName" :showError="showFieldError" />
</template>

<script setup lang="ts">
import { DateTime } from 'luxon';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import Datepicker from '@/components/validation/datepicker.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import StErrorMessage from '@/components/validation/st-error-message.vue';
import useValidation from '@/composables/useValidation';
import { LanguageCode } from '@/enums/language-code.enum';
import type { DatetimePickerProps } from '@/types/common/form.type';
import type { ValidationRule } from '@/types/validation.type';
import { getFormatByLocale, getUtcTimezone } from '@/utils/date.util';

const props = defineProps<DatetimePickerProps>();

const emit = defineEmits<{
  'update:modelValue': [v: string | Date | undefined];
}>();

const value = ref(props.modelValue);

const { locale } = useI18n();

const showFieldError = ref(false);

const setFieldValue = ref<(value: string | Date | undefined) => void>();

const fieldName = computed<string>(() => props.name ?? '');

const containerClassRenderer = computed(() => ({
  'py-16 px-24 rounded-xl flex gap-12 items-center bg-neutral-variant-2-pressed':
    !props.isReplaceContainerClass,
  [`${props.containerClass}`]: !!props.containerClass
}));

if (fieldName.value) {
  const rules = computed<ValidationRule | undefined>(() => props.rules);
  const { setValue, showError } = useValidation<string | Date | undefined>({
    fieldName: fieldName.value,
    rules,
    value
  });

  watch(
    () => showError.value,
    (v: boolean) => {
      showFieldError.value = v;
    }
  );

  setFieldValue.value = setValue;
}

const date = computed(() => {
  if (value.value) {
    return new Date(value.value);
  }
  return undefined;
});

const hour = computed(() => {
  if (value.value) {
    return new Date(value.value).getHours();
  }
  return 0;
});

const minute = computed(() => {
  if (value.value) {
    return new Date(value.value).getMinutes();
  }
  return 0;
});

watch(
  () => props.modelValue,
  (v: string | Date | undefined) => {
    value.value = v;
  }
);

const hours = Array.from({ length: 24 }, (_: number, i: number) => {
  const hour = i.toString().padStart(2, '0');
  return {
    label: hour,
    value: i
  };
});

const minutes = Array.from({ length: 60 }, (_: number, i: number) => {
  const minute = i.toString().padStart(2, '0');
  return {
    label: minute,
    value: i
  };
});

const utcTimezone = getUtcTimezone();

const changeDate = (e: string | Date | undefined) => {
  // set new date from e and hour and minute
  const newDate = e
    ? DateTime.fromJSDate(new Date(e)).set({ hour: hour.value, minute: minute.value }).toJSDate()
    : undefined;
  updateModalValue(newDate);
};

const changeHour = (e: number | string) => {
  const newDate = date.value
    ? DateTime.fromJSDate(date.value)
      .set({ hour: Number(e), minute: minute.value })
      .toJSDate()
    : undefined;
  updateModalValue(newDate);
};

const changeMinute = (e: number | string) => {
  const newDate = date.value
    ? DateTime.fromJSDate(date.value)
      .set({ hour: hour.value, minute: Number(e) })
      .toJSDate()
    : undefined;
  updateModalValue(newDate);
};

const updateModalValue = (e: string | Date | undefined) => {
  if (setFieldValue.value) {
    setFieldValue.value(e);
  }
  emit('update:modelValue', e);
};
</script>
<style lang="scss">
.cell.day.disabled {
  color: var(--stds-sem-color-disabled-variant-1) !important;
}
</style>
